<template>
  <div class="order-summary-taxes__wrap">
    <div 
      v-if="isMultiTax"
      :class="['order-summary-taxes', { 'order-summary-taxes__new': isMultiCountryCode }]"
    >
      <div class="summary-taxes-title">
        <div class="multi-taxes-txt">
          {{ taxsWrapTitle }}
          <s-popover
            :hide-close-icon="true"
            trigger="hover"
            placemen="bottom"
          >
            <p v-html="multiTips"></p>
            <template #reference>
              <span v-show="multiTips && showTaxIncludedPopover">
                <sui_icon_doubt_16px_1
                  size="14px"
                  color="#666666"
                />
              </span>
            </template>
          </s-popover>
        </div>
        <div
          v-if="isMultiCountryCode"
          class="multi-taxes-txt"
          @click.stop="showTaxInfoToggle = !showTaxInfoToggle"
        >
          {{ personAllTax.amountWithSymbol }}
          <component
            :is="showTaxInfoToggle ? 'sui_icon_more_up_14px_1' : 'sui_icon_more_down_14px_1'"
            :is-responsive-name="true"
            size="14px"
            color="#222222"
          />
        </div>
      </div>
      <div
        v-for="tax in taxesInfo"
        v-show="(isMultiCountryCode && showTaxInfoToggle) || !isMultiCountryCode"
        :key="`tax_${tax.taxType}`"
        class="summary-taxes-item she-clearfix txt-gray-dark3"
      >
        <span class="she-fl">
          {{ tax.title }}
          <s-popover
            :hide-close-icon="true"
            trigger="hover"
            placemen="bottom"
          >
            <p v-html="tax.hint"></p>
            <template #reference>
              <span
                v-show="tax.showIcon"
                class="she-fl__icon-wrap"
              >
                <sui_icon_doubt_14px_1
                  size="14px"
                  color="#767676"
                />
              </span>
            </template>
          </s-popover>
        </span>
        <span :class="['she-fr txt-gray-dark3', {'company_tax_active': tax.taxType === 'company_tax'}]">
          {{ `${ tax.taxType === 'company_tax' ? '-' : '' } ${ tax.taxPrice && tax.taxPrice.amountWithSymbol }` }}
        </span>
      </div>
    </div>

    <template v-else-if="!['MX']?.includes(countryCodeValue)">
      <CheckoutSummaryTax
        v-for="tax in newTaxes"
        :key="`tax_${tax.taxType}`"
        :tax="tax"
        :language="language"
        :is-show-ca-gst="isShowCaGst"
        :country-code="countryCodeValue"
      />
    </template>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { getTaxesConfig, appendColon } from 'public/src/pages/common/helpers.js'
import CheckoutSummaryTax from '../components/summary/CheckoutSummaryTax.vue'
import { sui_icon_doubt_16px_1, sui_icon_doubt_14px_1, sui_icon_more_up_14px_1, sui_icon_more_down_14px_1 } from '@shein-aidc/icon-vue3'

export default {
  components: {
    CheckoutSummaryTax,
    sui_icon_doubt_16px_1,
    sui_icon_doubt_14px_1,
    sui_icon_more_up_14px_1, 
    sui_icon_more_down_14px_1
  },
  props: {
    taxes: {
      type: Array,
      default: () => [],
    },
    language: {
      type: Object,
      default: () => ({})
    },
    isMultiTax: { // 是否需要展示一单多税样式
      type: Boolean,
      default: false
    },
    isShowCaGst: {
      type: Boolean,
      default: false
    },
    partOrderInfo: {
      type: Object,
      default: () => {}
    },
    taxesMYInfo: {
      /**
       * totalTax 实付（Taxes Included）
       * companyTax 公司抵扣（Sales Tax absorbed by SHEIN）
       * personTax 个人支付（Sales Tax)
       */
      type: Object,
      default: () => {}
    },
  },
  setup (props) {
    const multiTips = ref('')
    const showTaxInfoToggle = ref(false)
    const taxesInfo = ref([])
    const baseTaxesConfig = getTaxesConfig()

    const taxsWrapTitle = computed(() => {
      return appendColon(isCLCountryCode.value ? props.language.SHEIN_KEY_PC_31510 : props.language.SHEIN_KEY_PC_21334)
    })
    const newTaxes = computed(() => {
      // MY 站点公司税剔除ST
      return props.taxes.filter(f => !(f.taxPayType == 1 && f.taxType == 2))
    })
    const countryCodeValue = computed(() => {
      return props.partOrderInfo?.countryCode || ''
    })
    // 是否展示tax included小问号
    const showTaxIncludedPopover = computed(() => {
      return !['BR']?.includes(countryCodeValue.value)
    })
    const isMYCountryCode = computed(() => {
      return ['MY']?.includes(countryCodeValue.value)
    })
    const isCLCountryCode = computed(() => {
      return ['CL']?.includes(countryCodeValue.value)
    })
    const isMultiCountryCode = computed(() => {
      // MY, CL 站点下拉样式和一单多税一样
      return ['BR', 'MY', 'CL']?.includes(countryCodeValue.value)
    })
    const changeDutyAndVat = (taxArr) => {
      const dutyIndex = taxArr.findIndex(e => e.taxType == 7 && e.taxPayType == 2)
      if(dutyIndex === -1) return taxArr
      const vatIndex = taxArr.findIndex(e => e.taxType == 3 && e.taxPayType == 2)
      if(vatIndex === -1) return taxArr

      if (vatIndex > dutyIndex) return taxArr

      let temp = taxArr[dutyIndex]
      taxArr[dutyIndex] = taxArr[vatIndex]
      taxArr[vatIndex] = temp
      return taxArr
    }
    const rewriteConfig = computed(() => {
      if (['BR']?.includes(countryCodeValue.value)) return {
        3: {
          _name: 'ICMS',
          langKey: 'SHEIN_KEY_PC_26613',
          hintKey: 'SHEIN_KEY_PC_26614'
        },
        7: {
          _name: 'II',
          langKey: 'SHEIN_KEY_PC_26611',
          hintKey: 'SHEIN_KEY_PC_27362',
          showIcon: true
        },
        9: {
          _name: 'IVAT',
          langKey: 'SHEIN_KEY_PC_27345',
          hintKey: 'SHEIN_KEY_PC_27346',
          showIcon: true
        }
      }
      if (['CL']?.includes(countryCodeValue.value)) return {
        3: {
          _name: 'VAT',
          langKey: 'SHEIN_KEY_PC_31316',
          hintKey: 'SHEIN_KEY_PC_31319',
        },
        7: {
          _name: 'II',
          langKey: 'SHEIN_KEY_PC_31315',
          hintKey: 'SHEIN_KEY_PC_31318',
        },
        9: {
          _name: 'IVAT',
          langKey: 'SHEIN_KEY_PC_31317',
          hintKey: 'SHEIN_KEY_PC_31320',
          showIcon: true
        }
      }
      if (['SG']?.includes(countryCodeValue.value)) {
        return {
          1: {
            ...baseTaxesConfig?.[1] || {},
            hintKey: 'SHEIN_KEY_PC_28888',
          }
        }
      }
      return {}
    })
    const personAllTax = computed(() => {
      return isMYCountryCode.value ? props.taxesMYInfo?.totalTax || {} : props.partOrderInfo?.person_all_tax || {}
    })

    const taxesMYBuild = () => {
      const { companyTax, personTax } = props.taxesMYInfo
      const titleText = (value) => value?.includes(':') ? value : `${value}:`
      const taxesInfo = [
        {
          showIcon: true,
          name: 'person_tax',
          title: titleText(props.language.SHEIN_KEY_PC_16414),
          hint: props.language.SHEIN_KEY_PC_28890,
          taxPrice: personTax,
          taxType: 'person_tax'
        },
        {
          showIcon: true,
          name: 'company_tax',
          title: titleText(props.language.SHEIN_KEY_PC_28892),
          hint: props.language.SHEIN_KEY_PC_28893,
          taxPrice: companyTax,
          taxType: 'company_tax'
        }]
      return taxesInfo.filter(f => f.taxPrice?.amount && Number(f.taxPrice?.amount) > 0)
    }

    const taxesCLBuild = (tax) => {
      if (tax?.taxType == 9) {
        return {
          ...tax,
          taxType: 'company_tax'
        }
      }
      if (tax?.taxType == 3) {
        return {
          ...tax,
          taxPrice: props.partOrderInfo?.total_vat_tax || {}
        }
      }
      return tax
    }

    watch(() => [props.taxes, props.language], () => {
      const hits = []
      taxesInfo.value = []
      const multiTaxesConfig = getTaxesConfig({ rewriteConfig: rewriteConfig.value })
      const multiTaxes = isMultiCountryCode.value ? newTaxes.value : newTaxes.value?.filter(tax => ![8, 9]?.includes(tax?.taxType))
      // MY站点个人税
      if(isMYCountryCode.value) {
        props.taxesMYInfo && (taxesInfo.value = taxesMYBuild())
      } else {
        multiTaxes?.forEach(tax => {
          let { langKey, hintKey, showIcon } = multiTaxesConfig[tax?.taxType] || {}
          let titleText = props?.language[langKey] || ''
          titleText = titleText?.includes(':') ? titleText : `${titleText}:`
          taxesInfo.value.push({
            ...(isCLCountryCode.value ? taxesCLBuild(tax) : tax),
            title: titleText || '',
            hint: props?.language[hintKey] || '',
            showIcon: isMultiCountryCode.value ? showIcon : false,
          })
          hits.push(hintKey ? props?.language[hintKey] : '')
        })
        // cl 调整关税和vat税位置
        isCLCountryCode.value && (taxesInfo.value = changeDutyAndVat(taxesInfo.value))
      }
      multiTips.value = isMYCountryCode.value ? props.language.SHEIN_KEY_PC_28889 : 
        isCLCountryCode.value ? props.language.SHEIN_KEY_PC_31509 : hits.filter(tip => tip).join('<br/>') || ''

    }, { immediate: true })
    return {
      taxesInfo,
      multiTips,
      showTaxIncludedPopover,
      isMultiCountryCode,
      personAllTax,
      showTaxInfoToggle,
      countryCodeValue,
      isMYCountryCode,
      newTaxes,
      taxsWrapTitle,
      appendColon
    }
  }
}
</script>

<style lang="less">
.order-summary-taxes {
  border-top: 1px dashed #E5E5E5;
  padding-top: 10px;
  padding-bottom: 12px;
  box-sizing: content-box;
  &__new{
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  &.pb-0 {
    padding-bottom: 0;
  }
  .multi-taxes-txt{
    display: flex;
    align-items: center;
    &__icon-wrap {
      display: inline-block
    }
  }
  .summary-taxes-title {
    text-align: left;
    line-height: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .summary-icon-doubt {
    font-size: 12px;
    color: @sui_color_gray_light2;
  }
  .summary-taxes-item {
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    .margin-l(4px);
    .she-fl {
      &__icon-wrap {
        display: inline-block
      }
    }
  }
  .txt-gray-dark3 {
    color: @sui_color_gray_dark3;
  }
  .company_tax_active{
    color: #C44A01;
  }
}
</style>
